export const environment = {
  production: true,
  appVersion: require('../../package.json').version,
  cognitoUserPoolId: 'eu-west-1_8vrlvqWZG',
  cognitoAppClientId: '2vqqhuq529lo8n9bt5n3kdquk9',
  clientXAPIKey: 'EMZtXKdu8o2bFncWgDGDK2AT5VVnnNgX2qO2F1bG',
  clientAuthXAPIKey: 'zULErfaG4E6jd3a5qra0k2hFe70Yp437IXlIAsK9',
  'X-RapidAPI-Key': '6882d52e06mshe7087a8e2c51f03p10cc8fjsndc1388bcd169',
  'X-RapidAPI-Host': 'wft-geo-db.p.rapidapi.com',

  newApiUrl: 'https://9mu22gt18e.execute-api.eu-west-1.amazonaws.com/client',
  operatorApiUrl: 'https://rzn7x22y34.execute-api.eu-west-1.amazonaws.com/operator',

  //Where are these used?
  utmApiUrl: 'https://hqg84zb0na.execute-api.eu-west-1.amazonaws.com/dev',
  clienttestAPIURL: 'https://ya1q432if2.execute-api.us-east-1.amazonaws.com/',
  //Where are these used?

  accessToken: localStorage.getItem('CognitoIdentityServiceProvider.2vqqhuq529lo8n9bt5n3kdquk9.C2023-05-17295.accessToken'),

  maptilerKey: 'lmDPipRmm5Md5h8ZqWgb',
  //Mapbox
  mapBoxKey:
    'pk.eyJ1Ijoic3VicmF0MDA3IiwiYSI6ImNrYjNyMjJxYjBibnIyem55d2NhcTdzM2IifQ.-NnMzrAAlykYciP4RP9zYQ',

      // X API KEYS
  operatorXAPIKey: 'IBjCIv2N5H1bG18qkxtT94xxo9XPkRN38XIQiFs5',

  // ===================notification========================
  notificationHistoryApiUrl: 'https://49j1xkqmo0.execute-api.eu-west-1.amazonaws.com/uat/client-notification-history',
  notificationCategoryApiUrl: 'https://49j1xkqmo0.execute-api.eu-west-1.amazonaws.com/uat/client-notification-history-filter',
  notificationUnReadCountApiUrl: 'https://49j1xkqmo0.execute-api.eu-west-1.amazonaws.com/uat/client-notification-unread-count',
  notificationReadStatusApiUrl: 'https://49j1xkqmo0.execute-api.eu-west-1.amazonaws.com/uat/client-notification-mark-read',
  notificationFilterDropdown: 'https://49j1xkqmo0.execute-api.eu-west-1.amazonaws.com/uat/client-notification-filter-dropdown'
};
