import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { Observable, catchError, throwError } from 'rxjs';
import { User } from '../interfaces/home';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


const poolData = {
  UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
  ClientId: environment.cognitoAppClientId // Your client id here
};

const userPool = new CognitoUserPool(poolData);

/**
* @description
* (class will be used to register,signIn,resetPassword and verification authorization proccess)
*
* @author
* Deeksha Mishra
*/
@Injectable()
export class AuthorizationService {

  clientBaseURL;
  cognitoUser: any;
  registerUser: User;
  public httpOptions: any;
  public httpHeaders: any;


  constructor(private http: HttpClient, private router: Router) {
    this.clientBaseURL = environment.newApiUrl
  }

  /**
  * @description
  * This function initializes the HTTP headers and options with the client authentication x-api-key
  *
  * @return
  * httpOptions with x-api-key
  */
  headerInitializer() {
    this.httpHeaders = new HttpHeaders();
    this.httpHeaders = this.httpHeaders.append('x-api-key', environment.clientAuthXAPIKey);

    this.httpOptions = {
      headers: this.httpHeaders
    };
    return this.httpOptions;
  }


  /**
   * Handles the HTTP error response and logs an appropriate error message to the console.
   * Returns an Observable that emits a user-facing error message.
   * @param error The HttpErrorResponse object that contains information about the error.
   * @returns Observable<string>
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  };


  /**
  * @description
  *  Registers a new client user by sending a POST request to the server API endpoint.
  *
  * @param registerUser - The registration details of the client user.
  * @returns An Observable that resolves to the HTTP response from the server.
  */
  register(registerUser) {
    return this.http.post(`${this.clientBaseURL}/register-new-client`, registerUser)
    // .pipe(
    //   catchError(this.handleError)
    // );
  }

  /**
  * @description
  * reset service will call the api to reset the password for the particular user client
  *
  * @param
  * this will set new password for the client
  */
  resetPassword(registerUser) {
    return this.http.post(`${environment.newApiUrl}/registration/reset-password`, registerUser, this.headerInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * @description
  * forgot service will call the api to reset the password for the particular user client
  *
  * @param
  * user emailId
  */
  forgetPassword(registerUser) {
    return this.http.post(`${environment.newApiUrl}/registration/forgot-password`, registerUser, this.headerInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * @description
  * congnito service will call the api to get the client signIn
  *
  * @param
  * emailId password
  */
  signIn(email, password) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);

    return Observable.create(observer => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          observer.next(result);
          observer.complete();
        },
        onFailure: function (err) {
          // console.log(err);/
          observer.error(err);
        },
      });
    });
  }

  isLoggedIn() {
    return userPool.getCurrentUser() != null;
  }

  getAuthenticatedUser() {
    // gets the current user from the local storage
    return userPool.getCurrentUser();
  }

  logOut() {
    this.getAuthenticatedUser().signOut();
    localStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('login');
    // this.cognitoUser = null;
    // localStorage.removeItem('flightDetails');
    // localStorage.removeItem('userDetails');

  }

  /**
* @description
* profile service will call the api to get the client details to the dashboard
*
* @param
* client emailId
*/
  getProfileDetails(EmailId: any) {
    return this.http.post(`${this.clientBaseURL}/client-dashboard/client-get-profile-details`, EmailId, this.headerInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * @description
  * organization service will call the organization list to register client
  *
  * @param
  * - - - -
  */
  getOrganizationList() {
    return this.http.get(`${environment.newApiUrl}/registration/get-organization-list`, this.headerInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
* @description
* verification service will verify the OTP,email ID and set the password for the same user
*
* @param
* emailId, OTP, Password
*/
  getVerificationCode(registerUser) {
    return this.http.post(`${environment.newApiUrl}/registration/verification-code`, registerUser, this.headerInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * @description
  * service will call the api to validate if email or phone number is already registered
  *
  * @param
  * emailId phone
  */
  getDuplicateEmailPhone(registerUser) {
    return this.http.post(`${environment.newApiUrl}/registration/client-check-duplicate-for-registration`, registerUser, this.headerInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * @description
  * register service will call the api to register the client
  *
  * @param
  * client details
  */
  getClientRegister(registerUser) {
    return this.http.post(`${environment.newApiUrl}/registration/client-registration`, registerUser, this.headerInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * @description
  * verification service will verify the OTP,email ID and set the password for the same user
  *
  * @param
  * emailId, OTP, Password
  */
  getVerifyUserCode(registerUser) {
    return this.http.post(`${environment.newApiUrl}/registration/verify-client-verification-code`, registerUser, this.headerInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  /**
  * @description
  * this api call service is used to get the country list
  *
  */
  getCountries() {
    return this.http.get<any[]>('https://api.first.org/data/v1/countries');
  }
}

